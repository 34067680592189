import { DataSourceJsonData } from '@grafana/data'
import { K6DataSource } from 'datasource/datasource'
import { TestConfig } from '@grafana/k6-test-builder'
import { PROJECT_ROLE_TYPES, ORGANIZATION_ROLE_TYPES } from 'constants/roles'
import { FetchError, isFetchError } from '@grafana/runtime'
import { hasValue, PropertyPath } from 'utils/typescript'
import { ScheduledTest } from './scheduledTests'
import { BuiltinMetrics, BuiltinTracesMetrics } from 'datasource/models'
import type { Options } from 'k6/options'
import { BrowserUrls } from './k6Browser' // as it is used in the code below.
export type * from './k6Browser'
// eslint-disable-next-line no-duplicate-imports
import type { BrowserMetricSummary } from './k6Browser'
import { OnboardingType } from 'components/Onboarding'

export type ISODateString = string

interface LoadTestsV2ResponseMap {
  'k6-tests': Test[]
  'k6-test': Test
  'k6-run': TestRun
  'k6-runs': TestRun[]
  'k6-runs-trending-metrics': TrendingMetric[]
}

interface LoadTestsV2Meta {
  meta?: {
    count: number
  }
}

export type LoadTestsV2Response<T> = LoadTestsV2Meta & {
  [P in keyof LoadTestsV2ResponseMap as LoadTestsV2ResponseMap[P] extends T
    ? P
    : never]: LoadTestsV2ResponseMap[P]
}

export type DateLike = Date | string | number

export enum ErrorCode {
  // Custom Frontend errors
  PRODUCT_TOKEN_NOT_FOUND = -1000,
  AXIOS_REQUEST_CANCELED = -2000,
  NETWORK_ERROR = -2,
  UNKNOWN_ERR = -1,

  // Error codes copied from API repo api3/errors.py
  //  General errors 1-999
  UNKNOWN = 0,
  GENERAL = 1,
  VALIDATION = 2,
  NOT_FOUND = 3,
  NOT_ALLOWED = 4,
  NOT_AUTHENTICATED = 5,
  AUTHENTICATION_FAILED = 6,
  METHOD_NOT_ALLOWED = 7,
  ALREADY_MEMBER = 9,
  LIMITATION_REACHED = 10,
  INVALID_ARGUMENTS = 11,
  BAD_REQUEST = 12,

  REQUEST_RATE_LIMIT_REACHED = 100,

  //  Users codes 1000-1999
  ACCOUNT_INACTIVE = 1001,
  ACCOUNT_NOT_CONFIRMED = 1002,
  ACCOUNT_CONFIRM_EXPIRED = 1003,
  ACCOUNT_CONFIRMED = 1004,
  ACCOUNT_SOCIAL_AUTH_CANCELED = 1006,
  ACCOUNT_SOCIAL_AUTH_ALREADY_ASSOCIATED = 1007,
  ACCOUNT_TOKEN_ALREADY_EXISTS = 1008,
  ACCOUNT_BAD_PASSWORD_RESET_TOKEN = 1009,
  ACCOUNT_TOKENS_CANNOT_HAVE_ORGANIZATION = 1010,
  ACCOUNT_CREATION_NOT_ALLOWED = 1011,

  //  Project 2000-2999
  PROJECT_NOT_MEMBER = 2001,
  PROJECT_DELETE_DEFAULT = 2002,
  PROJECT_EXCEEDED_MAX_VIRTUAL_USER_HOURS = 2003,

  //  Organization 3000-3999
  ORGANIZATION_NOT_MEMBER = 3001,
  ORGANIZATION_ALREADY_EXISTS = 3002,
  ORGANIZATION_MAX_OWNED = 3004,
  ORGANIZATION_MAX_ACTIVE_CREDIT_CARDS = 3005,
  ORGANIZATION_INVALID_VAT = 3006,
  ORGANIZATION_TOKEN_ALREADY_EXISTS = 3050,
  ORGANIZATION_MAX_MEMBER = 3007,
  ORGANIZATION_OWNER_MUST_BE_ADMIN = 3008,
  ORGANIZATION_TOKEN_NOT_ALLOWED = 3009,
  ORGANIZATION_DELETE_DEFAULT = 3010,
  ORGANIZATION_DELETE_PREMIUM_SUBSCRIPTION = 3011,
  ORGANIZATION_TRANSFER_DEFAULT = 3012,

  //  Subscriptions 3100-3199
  SUBSCRIPTION_ALREADY_CANCELED = 3100,
  SUBSCRIPTION_CONFLICT = 3110,
  SUBSCRIPTION_NO_ADDON_PARENT = 3111,
  SUBSCRIPTION_PREV_TRIAL_EXISTS = 3112,
  SUBSCRIPTION_PREV_TRIAL_UNUSED = 3113,
  SUBSCRIPTION_NOT_COVERING_TEST = 3114,

  //  Proxy 3200-3299
  PROXY_FAILED_TO_START = 3200,
  PROXY_FAILED_TO_STOP = 3201,

  //  Notifications 3300-3999
  NOTIFICATION_CONFLICT = 3300,

  //  Tests 4000-4999
  TEST_INVALID_CONFIGURATION = 4000,
  TEST_COPY_NAME_NOT_PROVIDED = 4002,
  /* cspell:disable-next-line */
  TEST_RUN_NOT_ABORTABLE = 4003,
  TEST_RUN_NOT_EXPORTABLE = 4004,
  TEST_RUN_EXPORT_EXISTS = 4005,
  TEST_RUN_MAX_CONCURRENT = 4006,
  TEST_RUN_INSUFFICIENT_FUNDS = 4007,
  TEST_RUN_START_FAILED = 4009,
  TEST_RUN_BLACKLISTED_IP = 4010,
  TEST_RUN_BLACKLISTED_HOST = 4011,
  TEST_RUN_IP_TEST_LIMIT = 4012,
  TEST_RUN_START_MISSING_TEST_ID = 4013,
  TEST_LOAD_ZONE_LIMIT = 4014,
  TEST_RUN_STATUS_NOT_ALLOWED = 4015,
  TEST_RUN_METRIC_EXPORT_ERROR = 4016,
  TEST_DELETE_NOT_ALLOWED = 4017,
  TEST_RUN_HYBRID_LOAD_ZONES_NOT_ALLOWED = 4030,
  TEST_RUN_TOO_MANY_PRIVATE_LOAD_ZONES = 4031,
  TEST_RUN_CLOUD_PLZ_CUSTOM_IMAGE_NOT_ALLOWED = 4032,
  TEST_RUN_STATIC_IP_IN_BROWSER_TEST = 4033,

  //  User scenarios 5000-5999
  USER_SCENARIO_NAME_NOT_UNIQUE = 5001,
  USER_SCENARIO_COPY_NAME_NOT_PROVIDED = 5002,
  USER_SCENARIO_DATA_STORE_PERMISSION_DENIED = 5003,
  USER_SCENARIO_INUSE_BY_TEST = 5004,
  USER_SCENARIO_VALIDATIONS_MAX_RUNNING = 5005,

  //  Data files 6000-6999
  DATA_FILE_NAME_NOT_UNIQUE = 6001,
  DATA_FILE_UPLOAD_FAILED = 6002,
  DATA_FILE_CONVERSION_NOT_FINISHED = 6003,
  DATA_FILE_INUSE_BY_TEST = 6004,

  //  Integrations 7000-7999
  INTEGRATIONS_AUTHENTICATION_FAILED = 7001,
  INTEGRATIONS_API_CALL_FAILED = 7002,
  INTEGRATIONS_BAD_RESPONSE = 7003,

  SERVER_METRICS_AGENT_CONFIGURED = 7300,

  //  K6 overlap errors 8008-8999
  //  (the reason for not starting at 8000
  //  is for legacy reasons - errors 0 - 7 are already
  //  the same between api and k6 repos). These are echoed
  //  in k6-lib/constants/errors.py.

  TOO_MANY_REQUESTS = 8008,
  TOO_MANY_ANONYMOUS_TESTS_PER_HOUR = 8009,
  TOO_LONG_ANONYMOUS_TEST_DURATION = 8010,
  TEST_ALREADY_ABORTED = 8011,
  TOO_HIGH_METRICS_RATE = 8012,
  INTERNAL_INFRASTRUCTURE_ERROR = 8013,
  TEST_RUN_INVALID_DURATION = 8014,
  TEST_RUN_NO_DATA_TIMEOUT = 8015,
  TEST_RUN_TIMER_EXCEEDED = 8016,
  SLS_HAR_RECORDER_ERROR = 8017,
  /* cspell:disable-next-line */
  SLS_K6_CONV_ERROR = 8018,
  LOCKDOWN = 8019,
  INTERNAL_NETWORK_ERROR = 8020,
  USER_ALREADY_PROJECT_MEMBER = 8021,
  RECAPTCHA_CHALLENGE_FAILED = 8022,
  RECAPTCHA_CHALLENGE_REQUEST_ERROR = 8023,
  /* cspell:disable-next-line */
  SLS_K6_CONV_SCRIPT_ERROR = 8024,
}

export interface K6AppSettings {
  customText?: string
  customCheckbox?: boolean
}

export interface Settings {
  ds: K6DataSource
  query?: string
  index?: number
  components?: any[]
}

export type Section = 'projects' | 'tests' | 'runs'

export interface TestsPageSettings extends Settings {}

export enum MetricOrigin {
  BuiltIn = 'builtin',
  Script = 'script',
}

export type Tags = string[]

export interface Metric {
  check_id?: string
  group_id?: GroupId
  id: string
  name: BuiltinMetrics | string
  tag_names: Tags
  test_run_id: number
  type: MetricType
  origin: MetricOrigin

  /**
   * @deprecated This is a legacy field and should not be trusted.
   */
  contains: string
}

export interface Agg {}

export interface Value {
  timestamp: string
  value: any
}

/* cspell:disable-next-line */
export interface Serie {
  agg: Agg
  id: string
  method: string
  metric_id: string
  test_run_id: number
  values: Value[]
}

export interface Meta {
  count: number
}

export interface Stats {
  count: number
  max: number
  mean: number
  min: number
  p95: number
  p99: number
  stdev: number
}

export interface NullStats {
  count: 0
  max: null
  mean: null
  min: null
  p95: null
  p99: null
  stdev: null
}

interface NullGrpcMetricSummary {
  duration: NullStats
  requests_count: null
  rps_max: null
  rps_mean: null
}

export interface GrpcMetricSummary {
  duration: Stats
  requests_count: number
  rps_max: number
  rps_mean: number
}

export function hasGrpcMetricSummary(
  summary?: GrpcMetricSummary | NullGrpcMetricSummary
): summary is GrpcMetricSummary {
  return hasValue(summary?.requests_count)
}

export function toGrpcMetricSummary(
  summary?: GrpcMetricSummary | NullGrpcMetricSummary
) {
  return hasGrpcMetricSummary(summary) ? summary : null
}

export interface ThresholdsSummary {
  successes: number
  total: number
}

interface NullHttpMetricSummary {
  duration: NullStats
  failures_count: null
  requests_count: null
  rps_max: null
  rps_mean: null
}

export interface HttpMetricSummary {
  duration: Stats
  failures_count: number
  requests_count: number
  rps_max: number
  rps_mean: number
}

export function hasHttpMetricSummary(
  summary?: HttpMetricSummary | NullHttpMetricSummary
): summary is HttpMetricSummary {
  return hasValue(summary?.requests_count)
}

export function toHttpMetricSummary(
  summary?: HttpMetricSummary | NullHttpMetricSummary
) {
  return hasHttpMetricSummary(summary) ? summary : null
}

interface NullWsMetricSummary {
  connecting: NullStats
  msgs_received: null
  msgs_sent: null
  ping: NullStats
  session_duration: NullStats
  sessions: null
}

export interface WsMetricSummary {
  connecting: Stats
  msgs_received: number | null
  msgs_sent: number | null
  ping: Stats | NullStats
  session_duration: Stats
  sessions: number | null
}

export function hasWsMetricSummary(
  summary?: WsMetricSummary | NullWsMetricSummary
): summary is WsMetricSummary {
  return hasValue(summary?.sessions)
}

export function toWsMetricSummary(
  summary?: WsMetricSummary | NullWsMetricSummary
) {
  return hasWsMetricSummary(summary) ? summary : null
}

export interface ChecksMetricSummary {
  hits_successes: number
  hits_total: number
  successes: number
  total: number
}

export interface Summary {
  checks_metric_summary?: ChecksMetricSummary
  thresholds_summary?: ThresholdsSummary
  http_metric_summary?: HttpMetricSummary | NullHttpMetricSummary
  grpc_metric_summary?: GrpcMetricSummary | NullGrpcMetricSummary
  ws_metric_summary?: WsMetricSummary | NullWsMetricSummary
  browser_metric_summary?: BrowserMetricSummary
}

export interface Http {
  id: string
  test_run_id: number
  group_id?: GroupId | null
  scenario_id: string
  http_metric_summary: HttpMetricSummary
  method: string
  name: string
  status: number
  scenario: string
  expected_response: boolean
}

/**
 * These are options configured for each DataSource instance
 */
export interface CloudDataSourceOptions extends DataSourceJsonData {
  cloudAppUrl?: string | null
  cloudApiUrl?: string | null
  backendUrl?: string

  environment?: string
}

/**
 * Value that is used in the backend, but never sent over HTTP to the frontend
 */
export interface CloudSecureJsonData {
  apiToken?: string
  grafanaApiKey?: string
}

export interface TestRunListItem {
  /*organizationId: number;
  organizationName: string;
  projectId: number;
  projectName: string;
  testId: number;
  testName: string;
  testCreated: Date;*/
  testRunId: number
  testRunCreated: Date
  testRunStarted: Date
  testRunStartedEpoch: number
  testRunEnded: Date
  testRunEndedEpoch: number
  testRunStatus: number
  testRunResultStatus: number
  testRunVUs: number
  testRunDuration: number
  testRunLoadTime: number
}

export type OrganizationId = number

export interface OrganizationSettings {
  concurrency_policy?: 'queue' | 'abort'
}

export interface Organization {
  id: number
  name: string
  logo: string | null
  owner_id: number
  description: string
  billing_address: string
  billing_country: string
  billing_email: string
  vat_number: string
  created: string
  updated: string
  is_default: boolean
  is_saml_org: boolean
  subscription_ids: number[]
  load_zone_ids: number[]
  can_trial: boolean
  is_personal: boolean
  data_retention_days: number
  vuh: number
  vuh_max: number
  vuh_overcharge: number
  grafana_org_name: string | null
  grafana_org_slug: string | null
  grafana_stack_id: number
  grafana_stack_name: string | null
  grafana_stack_url: string | null
  grafana_rbac_enabled: boolean
  grafana_folder_uid: string | null
  org_settings?: OrganizationSettings
}

export type ProjectId = number

export interface Project {
  id: ProjectId
  name: string
  organization_id: number
  organization_name: string
  created: string
  is_default: boolean
  is_k6: boolean
  grafana_folder_uid: string | null
}

export interface UsageStats {
  cli_test_runs_started_count: number
  cloud_test_runs_started_count: number
  contributors_count: number
  projects_count: number
  tests_created_count: number
  test_runs_started_count: number
  test_runs_deleted_count: number
  test_run_duration_minutes: number
  test_runs_aborted_users_count: number
  test_runs_aborted_threshold_count: number
  test_runs_aborted_system_count: number
  test_runs_aborted_script_errors_count: number
  test_runs_aborted_limit_count: number
  test_runs_timed_out_count: number
  test_runs_success_count: number
  test_runs_fail_count: number
  ui_test_runs_started_count: number
  ingest_test_runs_started_count: number
  vuh_usage?: number
}

export interface ProjectContributor {
  user_id: number
  user_email: string
  user_full_name: string
  user_role_id: number
  gravatar: string
}

export interface ProjectSettings {
  project_id: number
  organization_id: number
  vuh_max_per_month: number | null
  vu_max_per_test: number | null
  vu_browser_max_per_test: number | null
  duration_max_per_test: number | null
}

export interface UsageReportTestRun extends TestRunBase {
  test_name: string
}

export interface ProjectWithStats extends Omit<UsageStats, 'projects_count'> {
  name: string
  id: number
  contributors: ProjectContributor[]
  deleted: boolean
  last_test:
    | (Omit<
        Test,
        | 'script'
        | 'vuh_cost_total'
        | 'trending_metrics'
        | 'config'
        | 'request_builder_config'
        | 'project_id'
        | 'test_runs'
      > & {
        test_run_ids: number[]
        baseline_test_run_id: number
      })
    | null
  last_test_run: UsageReportTestRun | null
}

export interface TrendDataPoint {
  run: TestRun
  calculated: boolean
  timestamp: string
  value: number | null
}

export interface Trend extends TrendingMetricConfig {
  values: TrendDataPoint[]
}

export interface TestWithTrends extends Test {
  trends: Trend[]
}

export type TestId = number

export interface Test {
  id: TestId
  config: TestConfig
  created: string
  creation_process: CreationProcess
  last_test_run_id: number | null
  name: string
  project_id: number
  request_builder_config: string | null
  script: string | null
  test_runs: TestRun[]
  trending_metrics: TrendingMetricConfig[]
  vuh_cost_total?: number
  vuh_browser_cost_total?: number
  schedule?: ScheduledTest
  updated?: string
}

export type HistogramQuantile<T extends number = number> =
  `histogram_quantile(0.${T})`

export type TrendCounterAggregation =
  | 'increase'
  | 'value'
  | 'rate'
  | 'max_rate(1)'

export type TrendGaugeAggregation =
  | 'sum(max by (instance_id))'
  | 'sum(avg by (instance_id))'
  | 'avg'
  | 'min'
  | 'max'
  | 'last'

export type TrendRateAggregation =
  | 'ratio'
  | 'rate_nz'
  | 'rate_z'
  | 'rate_total'
  | 'increase_nz'
  | 'increase_z'
  | 'increase_total'
  | 'value_nz'
  | 'value_z'
  | 'value_total'

export type TrendTrendAggregation =
  | 'histogram_avg'
  | 'histogram_max'
  | 'histogram_min'
  | 'histogram_stddev'
  | HistogramQuantile

export type TrendAggregation =
  | TrendCounterAggregation
  | TrendGaugeAggregation
  | TrendRateAggregation
  | TrendTrendAggregation

export interface TrendingMetricConfig {
  id: number
  labels_selector: string
  load_test_id: number
  metric_name: string
  metric_type: MetricType
  aggregation_function: TrendAggregation
}

export interface TrendingMetric extends TrendingMetricConfig {
  test_run_id: number
  value: number
  calculated: boolean
}

export interface Tests {
  'k6-runs-trending-metrics': TrendingMetric[]
  'k6-tests': Test[]
  meta: {
    count: number
  }
}

export enum BadgeColors {
  RED = 'red',
  BLUE = 'blue',
  GREEN = 'green',
  ORANGE = 'orange',
  PURPLE = 'purple',
  GRAY = 'gray',
}

export enum NamedColors {
  Red = 'red',
  Blue = 'blue',
  Green = 'green',
  Orange = 'orange',
  Purple = 'purple',
  Gray = 'gray',
}

export type Color = NamedColors | `#${string}`

export const BadgeColorCodes = {
  [BadgeColors.RED]: '#dc3545',
  [BadgeColors.GREEN]: '#28a745',
  [BadgeColors.ORANGE]: '#fbad37',
  [BadgeColors.GRAY]: '#b5b5b5',
  [BadgeColors.BLUE]: '#168ce8',
  [BadgeColors.PURPLE]: '#9b59b6',
}

export enum BadgeIcons {
  SPINNER = 'fa fa-spinner',
  CHECK = 'check',
  EXCLAMATION_TRIANGLE = 'exclamation-triangle',
  MINUS_CIRCLE = 'minus-circle',
  UPLOAD = 'cloud-upload',
}

export enum TestRunStatus {
  CREATED = -2,
  VALIDATED = -1,
  QUEUED = 0,
  INITIALIZING = 1,
  RUNNING = 2,
  FINISHED = 3,
  TIMED_OUT = 4,
  ABORTED_USER = 5,
  ABORTED_SYSTEM = 6,
  ABORTED_SCRIPT_ERROR = 7,
  ABORTED_THRESHOLD = 8,
  ABORTED_LIMIT = 9,
  UPLOADED = 10,
}

export const TestRunStatusText = {
  [TestRunStatus.CREATED]: 'Created',
  [TestRunStatus.VALIDATED]: 'Validated',
  [TestRunStatus.QUEUED]: 'Queued',
  [TestRunStatus.INITIALIZING]: 'Initializing',
  [TestRunStatus.RUNNING]: 'Running',
  [TestRunStatus.FINISHED]: 'Finished',
  [TestRunStatus.TIMED_OUT]: 'Timed out',
  [TestRunStatus.ABORTED_USER]: 'Aborted by user',
  [TestRunStatus.ABORTED_SYSTEM]: 'Aborted by system',
  [TestRunStatus.ABORTED_SCRIPT_ERROR]: 'Aborted script error',
  [TestRunStatus.ABORTED_THRESHOLD]: 'Aborted by threshold',
  [TestRunStatus.ABORTED_LIMIT]: 'Aborted by limit',
  [TestRunStatus.UPLOADED]: 'Uploaded',
}

export enum TestRunResultStatus {
  PASSED = 0,
  FAILED = 1,
}

export const TestRunResultStatusText = {
  [TestRunResultStatus.PASSED]: 'Test passed',
  // result_status is failed should only ever be set if there were thresholds
  // @see {https://github.com/grafana/k6-cloud/issues/750}
  [TestRunResultStatus.FAILED]: 'Failed by threshold',
}

export enum TestRunProcessingStatus {
  /* cspell:disable-next-line */
  UNRUN = 0,
  PROCESSING = 1,
  FINISHED = 2,
  ERROR = 3,
}

export enum TestRunDeleteStatus {
  DELETABLE = 0,
  /* cspell:disable-next-line */
  NOEXPIRE = 1,
  READONLY = 2,
  DELETED_USER = 3,
  DELETED_EXPIRE = 4,
  DELETION_IN_PROGRESS = 5,
}

export const RUN_PROCESS = {
  K6_INGEST: 'k6 to Ingest',
  K6_CLOUD: 'k6 to Cloud',
  K6_INGEST_WITH_THRESHOLDS: 'k6 to Ingest with Thresholds', // k6-operator (among other "things"?)
  K6_TO_CLOUD_SECURE: 'k6 to Cloud secure mode',
}

export enum CreationProcess {
  TestBuilder = 'UI-REQUEST-BUILDER',
  Script = 'UI-SCRIPT-HANDWRITTEN',
  ScriptChromeExtension = 'UI-SCRIPT-BROWSER-CHROME',
  ScriptFirefoxExtension = 'UI-SCRIPT-BROWSER-FIREFOX',
  BuilderChromeExtension = 'UI-REQUEST-BUILDER-BROWSER-CHROME',
  BuilderFirefoxExtension = 'UI-REQUEST-BUILDER-BROWSER-FIREFOX',
  Cloud = 'K6-CLOUD',
  Ingest = 'K6-INGEST',
  Unknown = 'UNKNOWN',
}

export const SCRIPT_CREATION_PROCESSES = [
  CreationProcess.Script,
  CreationProcess.ScriptChromeExtension,
  CreationProcess.ScriptFirefoxExtension,
]

export const BUILDER_CREATION_PROCESSES = [
  CreationProcess.TestBuilder,
  CreationProcess.BuilderChromeExtension,
  CreationProcess.BuilderFirefoxExtension,
]

export const K6TestRunStatusesActive = [
  TestRunStatus.RUNNING,
  TestRunStatus.CREATED,
  TestRunStatus.VALIDATED,
  TestRunStatus.QUEUED,
  TestRunStatus.INITIALIZING,
]

export enum BackgroundTaskType {
  TEST_RUN = 5,
  TEST_RESULT_EXPORT = 6,
}

export interface TestRunTask {
  type: BackgroundTaskType.TEST_RUN
  test_run_id: TestRunId
}

export interface ExportTask {
  type: BackgroundTaskType.TEST_RESULT_EXPORT
  test_run_id: TestRunId
}

export type BackgroundTask = TestRunTask | ExportTask

export const isTestRunTask = (task: BackgroundTask): task is TestRunTask =>
  task.type === BackgroundTaskType.TEST_RUN

export enum DELETION_POLICY {
  DELETABLE = 0,
  NOT_DELETABLE = 1,
}

export enum SUBSCRIPTION_TYPE {
  CUSTOM = 1,
  APP_DIRECT = 2,
  PREMIUM = 3,
  TRIAL = 4,
  FREE = 5,
  DATA_RETENTION = 7,
}

export enum SUBSCRIPTION_STATUS {
  ACTIVE = 1,
  CANCELED = 2,
  EXPIRED = 3,
  INACTIVE = 4,
  PAYMENT_LATE = 10,
}

export interface TestRunNode {
  load_zone_id: string
  public_ip: string
  size: string
}

export enum MetricType {
  COUNTER = 'counter',
  GAUGE = 'gauge',
  RATE = 'rate',
  TREND = 'trend',
  TRACES_TREND = 'tracesTrend',
}

export enum MetricCategory {
  STANDARD = 'standard',
  HTTP = 'http',
  GRPC = 'grpc',
  WS = 'ws',
  BROWSER_WEB_VITAL = 'browserWebVital',
  BROWSER = 'browser',
  SYSTEM = 'system',
  OTHER = 'other',
  CUSTOM = 'custom',
  UNKNOWN = 'unknown',
}

export type CheckMetricSummary = {
  fail_count: number
  success_count: number
  success_rate: number
}

export type Check = {
  group_id?: GroupId | null
  id: string
  metric_summary: CheckMetricSummary
  fail_count: number
  success_count: number
  success_rate: number
  name: string
  scenario_id: string
  test_run_id: number
}

export interface GroupLike {
  id: string
  test_run_id: number
  name: string
  group_duration_summary: Stats | undefined
  http_metric_summary: {
    duration: Stats
    duration_median: number | null
    failures_count: number | null
    requests_count: number | null
    rps_mean: number | null
  }
  grpc_metric_summary: {
    duration: Stats
    requests_count: number | null
    rps_mean: number | null
  }
  ws_metric_summary: {
    connecting: Stats
    ping: Stats
    session_duration: Stats
    msgs_received: number | null
    msgs_sent: number | null
    sessions: number | null
  }
  browser_metric_summary: BrowserMetricSummary
  browser_http_metric_summary: BrowserUrls['browser_http_metric_summary']
  checks_metric_summary: ChecksMetricSummary
}

export type GroupId = string
export type ScenarioId = string

export interface Group extends GroupLike {
  parent_id: string
  scenario_id: ScenarioId
}

export interface Scenario extends GroupLike {}

export type ThresholdPercentile = `p(${number})`

export type ThresholdStat =
  | 'count'
  | 'rate'
  | 'value'
  | 'avg'
  | 'min'
  | 'max'
  | 'med'
  | ThresholdPercentile

type CalcState = {
  max_created_at: Date
  max_time: Date
  min_time: Date
  tainted_value: number
}

export interface Threshold {
  id: number
  name: string
  stat: ThresholdStat
  tainted: boolean
  tainted_at?: string
  test_run_id: number
  value: number
  calculated_value: number
  calc_state: CalcState
}

export enum GRPC_STATUS {
  OK,
  CANCELLED,
  UNKNOWN,
  INVALID_ARGUMENT,
  DEADLINE_EXCEEDED,
  NOT_FOUND,
  ALREADY_EXISTS,
  PERMISSION_DENIED,
  RESOURCE_EXHAUSTED,
  FAILED_PRECONDITION,
  ABORTED,
  OUT_OF_RANGE,
  UNIMPLEMENTED,
  INTERNAL,
  UNAVAILABLE,
  DATA_LOSS,
  UNAUTHENTICATED,
}

export interface GrpcUrl {
  id: string
  test_run_id: number
  group_id: GroupId | null
  scenario_id: string | null
  name: string
  host: string
  method: string
  status: GRPC_STATUS
  grpc_metric_summary: GrpcMetricSummary
  scenario: string
  expected_response?: boolean
}

export interface WSValue {
  group_id?: GroupId | null
  id: string
  name: string
  scenario_id: string
  scenario: string
  status: number
  test_run_id: number
  ws_metric_summary: WsMetricSummary
}

export interface WSPayload {
  '@count': number
  value: WSValue[]
}

export interface BrowserResponse {
  group_id?: GroupId | null
  id: string
  name: string
  scenario_id: string
  scenario: string
  status: number
  test_run_id: number
  browser_metric_summary: BrowserMetricSummary
}

export interface BrowserResponsePayload {
  '@count': number
  value: BrowserResponse[]
}

export interface SeriesDataPoint {
  timestamp: string
  value: number
}

export interface Series {
  id: string
  metricId: string
  aggregation: string
  values: SeriesDataPoint[]
}

export interface Overview {
  checks_hits_successes: number
  checks_hits_total: number
  checks_successes: number
  checks_total: number
  http_req_duration_avg: number
  http_reqs_avg: number
  test_run_id: number
  thresholds_successes: number
  thresholds_total: number
  urls_hits_successes: number
  urls_hits_total: number
  urls_successes: number
  urls_total: number
}

export interface FormattedRuns {
  loadTimes: number[]
  dateStrings: string[]
  status: any
}

export type User = {
  application_type: unknown
  company: string
  company_size: number
  country: string
  date_joined: string
  email: string
  first_name: string
  gravatar_url: string
  has_password: boolean
  id: number
  industry: string
  is_saml_user: boolean
  job_title?: string
  last_name: string
  organization_ids: number[]
  status: number
  time_zone: string
}

export interface AdditionalUserData {
  app_json: {
    gck6_onboarding?: 0 | 1
    recent_projects?: Record<number, number[]>
    starred_projects?: Record<number, number[]>
    onboarding: {
      hasPriorK6Experience?: boolean | null
      dismissRecordingToast?: boolean
      [OnboardingType.CreateProject]?: boolean
      [OnboardingType.ManageUserPermissions]?: boolean
      [OnboardingType.RunCliTest]?: boolean
      [OnboardingType.RunCloudTest]?: boolean
      [OnboardingType.VisualizeResults]?: boolean
    }
  }
  last_used_organization_id: number | null
  last_used_project_id: number | null
  max_number_of_organizations: number
  user_id: number
}

export interface Account {
  user: User
  additional_user_data: null | [AdditionalUserData]
  organization_roles: [OrganizationRole, ...OrganizationRole[]]
  project_roles: ProjectRole[]
  project_roles_granted_by_teams: ProjectRoleGrantedByTeam[]
  organizations: Organization[]
  subscriptions: Subscription[]
  token: {
    key: string
  }
}

export type Awaited<T> = T extends PromiseLike<infer U> ? U : T

export enum MetricsExportStatus {
  NOT_STARTED = 0,
  EXPORTING = 1,
  FINISHED = 2,
  ERROR = 3,
}

export type MetricsExport = {
  config: unknown
  created: string
  end_time_lock: string
  error_detail: string
  error_detail_public: string
  export_status: MetricsExportStatus
  finished: string | null
  id: number
  load_test_run: number
  provider: string
  started: string
}

export type TestRunId = number

export interface TestRunExport {
  error_detail_public: string
  download_url: string | null
  export_status: number
}

export interface TestRunBase {
  id: TestRunId
  public_id: string | null
  test_id: number
  user_id: number

  created: string
  config?: { options?: Options } | null
  started: string | null
  ended: string | null

  browser_vus?: number
  vus: number
  duration: number
  run_process: string

  run_status: TestRunStatus
  result_status: TestRunResultStatus
  processing_status: TestRunProcessingStatus
  delete_status: TestRunDeleteStatus

  is_baseline: boolean

  load_time?: number | null
  was_scheduled?: boolean
}

export interface TestRun extends TestRunBase, Summary {
  project_id: number
  organization_id: number

  execution_duration: number

  script: string | null
  nodes: TestRunNode[]
  distribution: Array<[string, number]>

  note: string

  metrics_exports: MetricsExport[] | null
  export: TestRunExport | null

  error_code?: ErrorCode | null
  vuh_cost?: number
  vuh_browser_cost?: number
  error_detail?: string | null
}

export interface TestRunWithMetrics extends TestRun {
  metrics: TrendingMetric[]
}

export interface TestRuns {
  'k6-runs': TestRun[]
  meta?: Meta
}

export type TestRunsResponse = LoadTestsV2Response<TestRun[] | TrendingMetric[]>

export interface TimeSeriesValue {
  timestamp: string
  value: number
}

export interface TimeSeries {
  contains: string
  method: string
  metric_name: string
  metric_type: string
  test_run_id: number
  values: TimeSeriesValue[]
}

export interface TimeSeriesPayload {
  value: TimeSeries[]
}

export interface ProjectWithOrg extends Project {
  orgName: string
}

export enum TestSortOptions {
  LastTestRun = '-last_test_run',
  Created = '-created',
  Name = 'name',
}

export enum ProjectSortOptions {
  Created = '-created',
  Name = 'name',
}

export interface OrganizationRole {
  id: number
  organization_id: number
  role_id: ORGANIZATION_ROLE_TYPES
  user_email: string
  user_id: number
}

export type ProjectRole = Omit<OrganizationRole, 'role_id'> & {
  project_id: number
  role_id: PROJECT_ROLE_TYPES
  org_role_id: ORGANIZATION_ROLE_TYPES
}

export type ProjectRoleWithUser = ProjectRole & {
  user?: User | null
}

export interface ProjectRoleGrantedByTeam {
  team_id: number
  project_id: number
  role_id: PROJECT_ROLE_TYPES
}

export type TimeSeriesQueryType =
  | 'test_runs'
  | 'http_urls'
  | 'checks'
  | 'thresholds'
  | 'grpc_url_success'
  | 'grpc_urls'
  | 'ws_urls'

export type RateMethod =
  | 'ratio'
  | 'increase_total'
  | 'value_total'
  | 'rate_total'
  | 'increase_nz'
  | 'value_nz'
  | 'rate_nz'
  | 'increase_z'
  | 'rate_z'

export type TrendMethod =
  | 'histogram_max'
  | 'histogram_min'
  | 'histogram_avg'
  | 'histogram_stddev'
  | 'histogram_count_increase'
  | 'histogram_count_rate'
  | 'histogram_count_value'
  | 'histogram_cummin'
  | 'histogram_cummax'
  | 'histogram_cumavg'
  | 'histogram_cumstddev'
  | HistogramQuantile

export type CounterMethod = 'rate' | 'increase' | 'value' | 'cumrate'

export type GaugeMethod = 'max(last by (instance_id))'

export type VUMetricSpecialCaseAggregation = 'sum(last by (instance_id))'

export type AggregationMethod =
  | TrendMethod
  | RateMethod
  | CounterMethod
  | GaugeMethod
  | VUMetricSpecialCaseAggregation

export type Subscription = {
  id: number
  name: string
  description: string
  version: number
  starting: string
  expires: string
  status: number
  status_text: string
  price: number
  discount: number
  vuh: number
  vuh_max: number
  vuh_overcharge: number
  product_id: number
  period_days: number
  reset_days: number
  period_days_text: string
  rules: Rules
  rules_inheritance: RulesInheritance
  used_tests: number
  type: number
  is_addon: boolean
  addon_to: null
  addons: any[]
  /* cspell:disable-next-line */
  is_proratable: boolean
  is_usage: boolean
  /* cspell:disable-next-line */
  is_grandfatherable: boolean
}

export type Rules = {
  'apm.is_enabled': boolean
  'apm.metrics.max': number
  'apm.providers.allowed': string[]
  'apm.providers.max': number
  'beta.load_zones.allow': boolean
  'beta.payment_provider.allow': boolean
  'beta.test_worker.allow': boolean
  'data.retention_days.post_sub_expiry': number
  'data.retention_days': number
  'data.retention_safe.max': number
  'load_test.allow_start': boolean
  'load_test.delete_sensitive_data.allow': boolean
  'load_test.duration.max': number
  'load_test.extra_ip.max': number
  'load_test.instances.max': number
  'load_test.k6_cloud_execution.allow': boolean
  'load_test.k6_to_ingest_with_thresholds.allow': boolean
  'load_test.load_zones.max': number
  'load_test.new_relic.max': number
  'load_test.new_relic.metrics.max': number
  'load_test.ramp_steps.max': number
  'load_test.ramp_steps.min': number
  'load_test.ramp_time.min': number
  'load_test.sma.max': number
  'load_test.source_ips_multiple.max': number
  'load_test.tracks.max': number
  'load_test.tracks.min': number
  'load_test.url_groups.max': number
  'load_test.users.max': number
  'load_test.users.min': number
  'load_zone.loadimpact.stockholm1.allow': boolean
  'load_zone.loadimpact.stockholm2.allow': boolean
  'load_zone.private.load_zone_ids': any[]
  'load_zone.rax.chicago.allow': boolean
  'load_zone.rax.dallas.allow': boolean
  'load_zone.rax.london.allow': boolean
  'load_zone.rax.sydney.allow': boolean
  'organization.audit_trail.view': boolean
  'organization.executive_summary': boolean
  'organization.members.max': number
  'organization.projects.default_max_duration_per_test': number | undefined // Defined in seconds
  'organization.projects.default_max_vuh_per_month': number | undefined
  'organization.projects.default_max_vus_per_test': number | undefined
  'organization.projects.default_max_browser_vus_per_test': number | undefined
  'organization.static_ips.max': number
  'organization.tokens.max': number
  'organization.usage_reports': boolean
  'subscription.cost.affects_parent_proration': boolean
  'subscription.credits': number
  'tests.browser_users.max': number
  'tests.concurrent.max': number
  'tests.duration.max': number
  'tests.max': number
  'tests.threshold_configs.max': number
  'tests.users.max': number
  'tests.vuh.fractional_resolution': boolean
  'tests.vuh.max': number
  'user_scenarios.validations.max': number
}

export type Operator = 'equal' | 'not-equal'

export interface TagExpression {
  id?: string
  name: string
  operator: Operator
  values: string[]
}

export interface TagQuery {
  [tag: string]: TagExpression
}

export interface QueryRange {
  start: number
  end: number
}

export type MetricQueryType = 'series' | 'aggregate'

export interface MetricQuery {
  type: MetricQueryType
  metric: BuiltinMetrics | BuiltinTracesMetrics | string
  method: AggregationMethod
  range?: QueryRange
  tags: TagQuery
  groupBy?: string[]
}

export type RulesInheritance = {
  [key in keyof Rules]: {
    from_sub: number | null
    value: Rules[key]
  }
}

export type TagPayload = Array<{
  key: string
  value: string
}>

export type MetricPayload = {
  type: TimeSeriesQueryType
  method?: AggregationMethod
  metric?: string
  unit?: string
  label?: string
  uid?: string | number
  tags?: TagPayload
}

export type ApiError = FetchError<{
  error: {
    field_errors: Record<string, string> & {
      non_field_errors?: string[]
    }
  }
}>

export const isApiError = (e: any): e is ApiError => {
  return isFetchError(e) && !!e?.data?.error?.field_errors
}

export type OptionsValidationError = FetchError<{
  error: {
    message: string
    code: number
  }
}>

export const isOptionsValidationError = (
  e: any
): e is OptionsValidationError => {
  return isFetchError(e) && !!e?.data?.error?.message
}

export type TagId = string

export interface Tag {
  id: TagId
  test_run_id: TestRunId
  name: string
  too_many_values: boolean
  values: TagValue[]
}

export interface TagValue {
  id: string
  tag_id: string
  test_run_id: number
  value: string
}

export interface TagValuePayload {
  value: TagValue[]
}

export enum TestRunFilterType {
  Http = 'http',
  Thresholds = 'thresholds',
  Checks = 'checks',
  GRPC = 'grpc',
  WebSockets = 'websockets',
  Traces = 'traces',
}

export enum TestRunHttpFilterBy {
  URL = 'name',
  Status = 'status',
  Scenario = 'scenario',
  Method = 'method',
  ExpectedResponse = 'expected_response',
}

export enum TestRunThresholdsFilterBy {
  Name = 'threshold_name',
  Tainted = 'tainted',
}

export enum TestRunGRPCFilterBy {
  URL = 'grpc_url',
  Status = 'grpc_status',
  Scenario = 'grpc_scenario',
}

export enum TestRunChecksFilterBy {
  Name = 'check_name',
}

export enum TestRunWebSocketsFilterBy {
  URL = 'ws_url',
  Status = 'ws_status',
  Scenario = 'ws_scenario',
}

export enum TestRunTracesFilterBy {
  URL = 'http_url',
  Status = 'http_status_code',
  Scenario = 'test_run_scenario',
  Method = 'http_method',
  Group = 'test_run_group',
  SpanName = 'span_name',
  ServiceName = 'span_service_name',
  SpanKind = 'span_kind',
  SpanStatus = 'span_status_code',
}

export enum TestRunBrowserHttpFilterBy {
  URL = 'browser_name',
  Scenario = 'browser_scenario',
}

export enum TestRunProfileFilterBy {
  Scenario = 'profile_scenario',
  URL = 'profile_name',
}

type TestRunFilterBase<T> = {
  by: T
  label?: string
  values: string[]
}

export type TestRunHttpFilter = TestRunFilterBase<TestRunHttpFilterBy>
export type TestRunThresholdsFilter =
  TestRunFilterBase<TestRunThresholdsFilterBy>
export type TestRunChecksFilter = TestRunFilterBase<TestRunChecksFilterBy>
export type TestRunGRPCFilter = TestRunFilterBase<TestRunGRPCFilterBy>
export type TestRunWebSocketsFilter =
  TestRunFilterBase<TestRunWebSocketsFilterBy>
export type TestRunTracesFilter = TestRunFilterBase<TestRunTracesFilterBy>
export type TestRunBrowserHttpFilter =
  TestRunFilterBase<TestRunBrowserHttpFilterBy>
export type TestRunProfileFilter = TestRunFilterBase<TestRunProfileFilterBy>

export type TestRunFilter =
  | TestRunHttpFilter
  | TestRunThresholdsFilter
  | TestRunChecksFilter
  | TestRunGRPCFilter
  | TestRunWebSocketsFilter
  | TestRunTracesFilter
  | TestRunBrowserHttpFilter
  | TestRunProfileFilter

export type TestRunFilterWithOptions = TestRunFilter & {
  required?: boolean
  multiSelect?: boolean
  allowCustomValue?: boolean
}

export type TestRunFilterBy =
  | TestRunHttpFilterBy
  | TestRunThresholdsFilterBy
  | TestRunChecksFilterBy
  | TestRunGRPCFilterBy
  | TestRunWebSocketsFilterBy
  | TestRunTracesFilterBy
  | TestRunBrowserHttpFilterBy
  | TestRunProfileFilterBy

export type TestRunNotePayload = Pick<TestRun, 'note'>

export interface TestRunStats {
  id: User['id']
  ui_started_test_runs: number
  cli_started_test_runs: number
}

export interface UserTokenRegenResponse {
  user_token: { token: string }
}

export enum GrafanaSyncStatus {
  NO_SYNC_STATUS = 0,
  SYNC_STATUS_STARTED = 1,
  SYNC_STATUS_FINISHED = 2,
  SYNC_STATUS_FAILED = 3,
}

export interface AppInitializedResponse {
  initialized: boolean
  error?: string
  grafana_rbac_enabled: boolean
  grafana_sync_status: GrafanaSyncStatus
}

export interface OrganizationToken {
  name: string
  token: string
}

export interface OrganizationTokensResponse {
  organization_tokens: OrganizationToken[]
}

export interface EnvironmentVariable {
  id?: number
  name: string
  value: string
  description?: string
}

export interface CreateProjectRoleBody {
  role_id: number
  project_id: number
  user_id: number
}

export interface CreateProjectBody
  extends Partial<Omit<ProjectSettings, 'project_id'>> {
  organization_id: number
  name: string
}

export interface UpdateTestBody {
  script: string
  name: string
  id: number
}

export interface CreateTestBody {
  script: string
  name: string
  project_id: number
}

export type CreateEnvironmentVariableBody = Omit<EnvironmentVariable, 'id'>

export interface SortOptions<T> {
  sortBy: PropertyPath<T>
  direction: 'asc' | 'desc'
}

export interface PagedItems<T> {
  items: T[]
  count: number
}

export interface TestRunAnalysis {
  main: TestRun
  compareWith?: TestRun
}

export interface UpdateRoleBody {
  id: number
  project_id: number
  user_id: number
  role_id: ProjectRole['role_id']
}

export type StaticIPsLoadZonesPayload = Record<string, number>

export enum StaticIPStatus {
  PROVISIONING = 0,
  PROVISIONING_ERROR = 1,
  PROVISIONED = 2,
  RELEASING = 3,
  RELEASING_ERROR = 4,
  PROVISIONING_ERROR_QUOTA_REACHED = 5,
}

export interface StaticIP {
  id: number
  ip: string
  provisioning_status: StaticIPStatus
  load_zone_identifier: string
}

export interface CalculateStaticIPsPayload {
  loadzones: StaticIPsLoadZonesPayload
  vus: number
}

export interface CalculateStaticIPsResponse {
  object: Record<string, number>
}

export type NewsFeedItem = {
  title: string
  link: string
  description: string
  pubDate: string
}
